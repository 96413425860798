import React, { StrictMode } from 'react'
import { render } from 'react-dom'

import 'normalize.css'
import './assets/css/global.css'

import App from './app'

render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('veicolo-app')
)
