import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Header from './components/header'
import Main from './components/main'
import Footer from './components/footer'

import routes from './routes'
import E404 from './pages/404'

const App = () => (
    <Router>
        <Helmet titleTemplate='Veicolo - %s' defaultTitle='Veicolo: info targa'>
            <html lang='it' />
            {/* <base target="_blank" href="https://veicolo.app/" /> */}
            <meta charset='utf-8' />
            <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
            />
            <meta
                name='description'
                content="Patente, libretto, RCA, furto. L'app numero #1 in Italia per cercare informazioni su auto e moto."
            />
            <link rel='icon' href='%PUBLIC_URL%/favicon.ico' />
            <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href='/favicon-32x32.png'
            />
            <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href='/favicon-16x16.png'
            />
            <link
                rel='apple-touch-icon'
                sizes='180x180'
                href='/apple-touch-icon.png'
            />
            <link rel='manifest' href='/manifest.json' />
            <link
                rel='mask-icon'
                href='/safari-pinned-tab.svg'
                color='#2a5eb9'
            />
            <meta name='msapplication-TileColor' content='#121212' />
            <meta name='theme-color' content='#ffffff' />
        </Helmet>
        <Container>
            <Header />
            <Main>
                <Switch>
                    {routes.map(
                        (
                            { path = '/', component: Child = () => <div /> },
                            idx
                        ) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Route path={path} key={`${path}-${idx}`} exact>
                                <Child />
                            </Route>
                        )
                    )}
                    <Route path='/'>
                        <E404 />
                    </Route>
                </Switch>
            </Main>
            <Footer />
        </Container>
    </Router>
)
export default App

const Container = styled.div``
